@tailwind base;
@tailwind components;
@tailwind utilities;

.fc {
    background-color: white !important;
    border-radius: 12px;
    padding: 12px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
    transition: all 0.3s ease-in-out;
  }
  
  /* 🔹 Calendar Header (Toolbar) */
  .fc-toolbar {
    background: rgba(255, 255, 255, 0.8) !important; /* Soft glassmorphism effect */
    padding: 12px;
    border-radius: 8px;
    backdrop-filter: blur(10px);
    border-bottom: 2px solid #e5e7eb;
  }
  
  /* 🔹 Calendar Title */
  .fc-toolbar-title {
    font-size: 1.6rem !important;
    font-weight: 700 !important;
    color: #1e293b !important; /* Dark blue-gray */
  }
  
  /* 🔹 Buttons in Toolbar */
  .fc-button {
    background: linear-gradient(135deg, #2563eb, #3b82f6) !important; /* Gradient blue */
    color: white !important;
    border: none !important;
    border-radius: 6px !important;
    padding: 8px 14px !important;
    font-size: 0.9rem !important;
    transition: all 0.3s ease-in-out;
  }
  
  /* 🔹 Hover effect for buttons */
  .fc-button:hover {
    background: linear-gradient(135deg, #1e40af, #2563eb) !important;
    transform: scale(1.05);
  }
  
  /* 🔹 Active button effect */
  .fc-button-active {
    background: #1e3a8a !important;
  }
  
  /* 🔹 Calendar Grid Styling */
  .fc-daygrid-day {
    border: 1px solid #e5e7eb !important;
    transition: background 0.3s;
  }
  
  /* 🔹 Calendar List View */
  .fc-list {
    background: white !important;
    border-radius: 10px;
    border: 1px solid #e5e7eb;
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.05);
  }
  
  /* 🔹 Event Styling */
  .fc-event {
    background: linear-gradient(135deg, #3b82f6, #60a5fa) !important; /* Smooth blue gradient */
    border: none !important;
    border-radius: 8px !important;
    padding: 6px 10px !important;
    box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.2); /* Add depth */
    transition: all 0.3s ease-in-out;
  }
  
  /* 🔹 Event Hover Effect */
  .fc-event:hover {
    background: linear-gradient(135deg, #2563eb, #1e40af) !important;
    transform: scale(1.05);
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
  }
  
  /* 🔹 Event Text (Bold & Readable) */
  .fc-event-title,
  .fc-event-time {
    font-weight: 600 !important;
    color: white !important;
    font-size: 1rem !important;
    text-align: center !important;
  }
  
  /* 🔹 Hide Event Time */
  .fc-event-time {
    display: none !important;
  }
  
  /* 🔹 Reduce Event Height & Width */
.fc-event {
    background: linear-gradient(135deg, #3b82f6, #60a5fa) !important; /* Smooth blue gradient */
    border: none !important;
    border-radius: 6px !important; /* Slightly rounded */
    padding: 3px 6px !important; /* Reduce padding */
    font-size: 0.85rem !important; /* Reduce font size */
    height: auto !important; /* Adjust height dynamically */
    min-height: 24px !important; /* Set a minimum height */
    max-width: 90% !important; /* Reduce width slightly */
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.15); /* Add depth */
    transition: all 0.2s ease-in-out;
  }
  
  /* 🔹 Event Hover Effect (Slightly Enlarges) */
  .fc-event:hover {
    background: linear-gradient(135deg, #2563eb, #1e40af) !important;
    transform: scale(1.03);
  }
  
  /* 🔹 Event Title (Smaller & Readable) */
  .fc-event-title {
    font-weight: 600 !important;
    color: white !important;
    font-size: 0.85rem !important; /* Reduce font size */
    text-align: center !important;
    white-space: nowrap !important; /* Prevent wrapping */
    overflow: hidden !important;
    text-overflow: ellipsis !important; /* Show "..." if text is too long */
  }
  
  /* 🔹 Hide Event Time */
  .fc-event-time {
    display: none !important;
  }
  
  /* 🔹 Reduce List View Event Height */
  .fc-list-item {
    padding: 6px 10px !important;
    min-height: 28px !important;
  }
  
  