.som {
    font-family: Arial, sans-serif;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 700px;
    height: auto;
    background-color: #f4f4f4;
    margin: 0 auto;
  }
  
  .kontainer {
    display: flex;
   /* gap: 100px;*/
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .kox {
    width: 200px;
    min-height: 300px;
    padding: 5px;
    border: 2px solid #ccc;
    background: #fff;
  }
  
  .targetBox >.item {
    padding: 5px;
    margin: 5px;
    background: rgb(187, 187, 187);
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;
   
  }

  .sourceBox >.item{
    padding: 5px;
    margin: 5px;
    background: lightblue;
    cursor: pointer;
    text-align: center;
    border-radius: 5px;
    font-size: 12px;

  }
  
  .kelected {
    background: orange !important;
  }
  
  .kave-button {
    margin-top: 0px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    background-color: green;
    color: white;
    border: none;
    border-radius: 5px;
  }
  
  .koox{width: 100%; height: auto; display: flex;}
  .koox > svg {
    position: absolute; 
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 0;
  }
  
  .arrow {
    fill: none;
    stroke-width: 2;
    cursor: pointer;
  }
  
  
  .delete-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }

  .kanel {
    width: 100%;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.kanel-header {
    background: #007bff;
    color: white;
    padding: 12px 16px;
    font-size: 18px;
    font-weight: bold;
}

.kanel-body {
    padding: 16px;
    font-size: 14px;
    color: #333;
}

.kanel-footer {
    background: #f1f1f1;
    padding: 12px;
    text-align: right;
}

.kanel-footer button {
    background: #007bff;
    color: white;
    border: none;
    padding: 8px 8px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}

.kanel-footer button:hover {
    background: #0056b3;
}

.delete-btn {
    background: rgb(0, 0, 0);
    color: white;
    border: none;
    /*padding: 5px;*/
    width: 16px;
    height: 16px;
    font-size: 11px;
    border-radius: 50%;
    cursor: pointer;
    position: relative;
    transform: translate(-50%, -50%);
    transition: opacity 0.3s ease;
    left: 15px;
    top: 8px;
}
  
  svg path:hover + .delete-btn {
    opacity: 1;
  }
  