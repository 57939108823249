@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
    h1 {
      @apply text-4xl font-semibold;
    }
    h3 {
      @apply text-2xl font-semibold;
    }
    h4 {
      @apply text-xl font-semibold;
    }
    h5 {
      @apply text-lg font-semibold;
    }
    h6 {
      @apply text-sm font-semibold;
    }
    h2 {
      @apply text-3xl font-semibold;
    } 
}